import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import name from "redux/slices/example";
import news from "redux/slices/news";
import flags from "redux/slices/flags";
import events from "redux/slices/events";
import surveys from "redux/slices/surveys";
import questions from "redux/slices/questions";
import notifications from "redux/slices/notifications";
import projects from "redux/slices/projects";
import reports from "redux/slices/reports";
import profile from "redux/slices/profile";
import resources from "redux/slices/resources";
import knowledge from "redux/slices/knowledge";

import citizen from "redux/slices/Citizen";
import responses from "redux/slices/responses";

import categories from "redux/slices/categories";
import alerts from "redux/slices/alerts";
import emails from "redux/slices/communication/emails";

const store = configureStore({
  reducer: combineReducers({
    news,
    events,
    notifications,
    flags,
    profile,
    surveys,
    questions,
    projects,
    reports,
    resources,
    knowledge,
    citizen,
    responses,
    categories,
    emails,
    alerts,
  }),
  //preloadedState: {your state object for initialization or rehydration}
});

export default store;

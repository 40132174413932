/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useState } from "react";
import { SpiningPage } from "components/atoms/icons";
import { Col, Layout, Row, Select, Popover } from "antd";
import { HappyFaceIcon } from "components/atoms/icons";
import GaugeChart from "react-gauge-chart";
import { SadFaceIcon } from "components/atoms/icons";
import { NeutralFaceIcon } from "components/atoms/icons";
import "./Analytics.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAlertCategories } from "redux/slices/alerts";
import alertAPI from "api/alertAPI";
import { getCurrentProjectId } from "utils/common";

const { Option } = Select;

const renderLoader = () => <SpiningPage opacity={1} />;

const GraphHeader = ({ title, items = [], defaultValue, onChange }) => {
  const [options, setOptions] = useState(items);
  const fetchedCategories = useSelector((state) => state.alerts.categories);

  useEffect(() => {
    setOptions(fetchedCategories);
  }, [fetchedCategories]);

  const changeOption = (e) => {
    console.log({ cambiando: e });
    onChange(e);
  };

  return (
    <Row justify="space-between" align="middle">
      <Col className="title">
        <p>{title}</p>
      </Col>
      <Col className="select">
        <BorderlessSelect
          items={options}
          className="selectbox-title"
          defaultValue={defaultValue}
          total={defaultValue?.total_mentions}
          onChange={(value) => changeOption(value)}
        />
      </Col>
    </Row>
  );
};

const GraphCard = ({ name, title, withFooter, children, items, onChange }) => {
  const fetchedCategories = useSelector((state) => state.alerts.categories);
  const [options, setOptions] = useState(fetchedCategories);

  useEffect(() => {
    setOptions(fetchedCategories);
  }, [fetchedCategories]);

  useEffect(() => {
    console.log({ options });
  }, [options]);

  return (
    <Col
      className="graph-container"
      lg={24}
      xl={12}
      xxl={12}
      md={24}
      sm={24}
      xs={24}
    >
      <div className="graph-content">
        {options && options.length > 0 && (
          <GraphHeader
            title={title}
            defaultValue={name}
            items={options}
            onChange={onChange}
          />
        )}
        <div className={withFooter ? "graph-with-footer" : "graph"}>
          {children}
        </div>

        {withFooter && (
          <Row align="middle" className="footer">
            <div className="feeling-container">
              <p>
                <HappyFaceIcon height={28} top={0} left={0} />
              </p>
              <p className="feeling-title">Positive</p>
            </div>
            <div className="feeling-container">
              <p>
                <NeutralFaceIcon height={28} top={0} left={0} />
              </p>
              <p className="feeling-title">Neutral</p>
            </div>

            <div className="feeling-container">
              <p>
                <SadFaceIcon height={28} top={0} left={0} />
              </p>
              <p className="feeling-title">Negative</p>
            </div>
          </Row>
        )}
      </div>
    </Col>
  );
};

const Bar = ({ color = "green", width = 30, value }) => (
  <Popover content={value}>
    <div
      style={{ backgroundColor: color, height: 15, width, cursor: "pointer" }}
    ></div>
  </Popover>
);

const SocialBarGraph = ({ positive, neutral, negative, keyword }) => (
  <Row
    className="social-bar-container"
    align="middle"
    justify="start"
    flex="auto"
  >
    <div className="graph-legend" style={{ marginRight: 20, width: "20%" }}>
      <p>{keyword}</p>
    </div>

    <Bar
      color={positive.color}
      width={positive.value * 4}
      value={positive.value}
    />
    <Bar
      color={neutral.color}
      width={neutral.value * 4}
      value={neutral.value}
    />
    <Bar
      color={negative.color}
      width={negative.value * 4}
      value={negative.value}
    />
  </Row>
);

const TextWithIcon = ({ img, name }) => (
  <Row className="social-bar-container" align="middle">
    <img src={img} alt="social" width="22" height="22" />
    <p style={{ marginLeft: 10 }}>{name}</p>
  </Row>
);

const SocialTable = ({ keyName, value, items = [] }) => (
  <>
    <div className="table-header">
      <p className="table-header-title">{keyName}</p>
      <p className="table-header-title">{value}</p>
    </div>
    <div className="table-content">
      {items.map(({ key, value }, idx) => (
        <div className="table-content-wrapper" key={idx}>
          <div className="table-record-keyname">
            <div className="table-record-content">{key}</div>
          </div>
          <div className="table-record-value">
            <div className="table-record-content">{value}</div>
          </div>
        </div>
      ))}
    </div>
  </>
);

const BorderlessSelect = ({ items, defaultValue, onChange, className }) => {
  return (
    <Select
      dropdownStyle={{ minWidth: "fit-content" }}
      className={className}
      defaultValue={defaultValue}
      bordered={false}
      onChange={onChange}
    >
      {items?.map((item) => (
        <Option key={item.id ?? item.value} value={item.id ?? item.value}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
};

const SocialAnalytics = () => {
  const [gaugeData, setGaugeData] = useState();
  const [languagesData, setLanguagesData] = useState();
  const [keywordsData, setKeywordsData] = useState();
  const [socialData, setSocialData] = useState();
  const [loading, setLoading] = useState(true);
  const categoriesFetched = useSelector((state) => state.alerts.categories);
  const fetchedAnalytics = useSelector((state) => state.alerts.analytics);
  const fetchingLoading = useSelector((state) => state.alerts.loading);
  const [categories, setCategories] = useState(categoriesFetched);
  const [selectedOptionGauge, setSelectedOptionGauge] = useState();
  const [selectedOptionKeywords, setSelectedOptionKeywords] = useState();
  const [selectedOptionLanguages, setSelectedOptionLanguages] = useState();
  const [selectedOptionSources, setSelectedOptionSources] = useState();

  const [loadingStates, setLoadingStates] = useState({
    gauge: true,
    language: true,
    source: true,
    keyword: true,
  });

  const project_id = getCurrentProjectId();
  const dispatch = useDispatch();

  const fetchData = async (option) => {
    const results = await alertAPI.getAlertAnalytics(project_id, option);
    return results;
  };

  const changeStatusLoading = (key, status) => {
    setLoadingStates((current) => ({ ...current, [key]: status }));
  };

  useEffect(() => {
    if (selectedOptionGauge) {
      changeStatusLoading("gauge", true);
      fetchData(selectedOptionGauge)
        .then((result) => {
          const analytics = result.data.body;
          setGaugeData(analytics);
          changeStatusLoading("gauge", false);
        })
        .catch((error) => {
          changeStatusLoading("gauge", false);
          console.log({ error });
        });
    }
  }, [selectedOptionGauge]);

  useEffect(() => {
    if (selectedOptionKeywords) {
      changeStatusLoading("keyword", true);
      fetchData(selectedOptionKeywords)
        .then((result) => {
          changeStatusLoading("keyword", false);
          const analytics = result.data.body;
          setKeywordsData(
            { ...analytics }.data.map((keyword) => ({
              key: (
                <SocialBarGraph
                  positive={{ color: "#4CCEB7", value: keyword.positive }}
                  neutral={{ color: "#FFB547", value: keyword.neutral }}
                  negative={{ color: "#E91E63", value: keyword.negative }}
                  keyword={keyword.name}
                />
              ),
              value: `${keyword.percentage}%`,
            }))
          );
        })
        .catch((error) => {
          changeStatusLoading("keyword", false);
          console.log({ error });
        });
    }
  }, [selectedOptionKeywords]);
  useEffect(() => {
    if (selectedOptionLanguages) {
      changeStatusLoading("language", true);
      fetchData(selectedOptionLanguages)
        .then((result) => {
          changeStatusLoading("language", false);
          const analytics = result.data.body;
          setLanguagesData(
            { ...analytics }.languages.map((lang) => ({
              key: <TextWithIcon name={lang.lang.name} img={lang.lang.img} />,
              value: `${lang.percentage}%`,
            }))
          );
        })
        .catch((error) => {
          changeStatusLoading("language", false);

          console.log({ error });
        });
    }
  }, [selectedOptionLanguages]);
  useEffect(() => {
    if (selectedOptionSources) {
      changeStatusLoading("source", true);

      fetchData(selectedOptionSources)
        .then((result) => {
          changeStatusLoading("source", false);
          const analytics = result.data.body;
          setSocialData(
            { ...analytics }.sources.map((source) => ({
              key: <TextWithIcon name={source.name} img={source.img} />,
              value: `${source.total}%`,
            }))
          );
        })
        .catch((error) => {
          changeStatusLoading("source", false);
          console.log({ error });
        });
    }
  }, [selectedOptionSources]);

  useEffect(() => {
    dispatch(getAlertCategories());
  }, []);

  useEffect(() => {
    if (categoriesFetched.length > 0) {
      fetchData(categoriesFetched[0].id)
        .then((result) => {
          const analytics = result.data.body;
          setGaugeData(analytics);
          setKeywordsData(
            { ...analytics }.data.map((keyword) => ({
              key: (
                <SocialBarGraph
                  positive={{ color: "#4CCEB7", value: keyword.positive }}
                  neutral={{ color: "#FFB547", value: keyword.neutral }}
                  negative={{ color: "#E91E63", value: keyword.negative }}
                  keyword={keyword.name}
                />
              ),
              value: `${keyword.percentage}%`,
            }))
          );
          setLanguagesData(
            { ...analytics }.languages.map((lang) => ({
              key: <TextWithIcon name={lang.lang.name} img={lang.lang.img} />,
              value: `${lang.percentage}%`,
            }))
          );
          setSocialData(
            { ...analytics }.sources.map((source) => ({
              key: <TextWithIcon name={source.name} img={source.img} />,
              value: `${source.total}%`,
            }))
          );
          setLoadingStates({
            gauge: false,
            keyword: false,
            language: false,
            source: false,
          });
        })
        .catch((error) => {
          console.log({ error });
        });
    }
    setCategories(categoriesFetched);
  }, [categoriesFetched]);

  useEffect(() => {
    setLoading(fetchingLoading);
  }, [fetchingLoading]);

  return (
    <Suspense fallback={renderLoader()}>
      <Layout className="analytics">
        {!categories && !fetchedAnalytics ? (
          <SpiningPage opacity={1} />
        ) : (
          <Row justify="space-between" align="top" className="graphs">
            <GraphCard
              options={categories}
              name={categories[0]?.name}
              onChange={setSelectedOptionGauge}
              title="Sentiment"
              withFooter
            >
              {loadingStates.gauge ? (
                <SpiningPage opacity={1} />
              ) : (
                <GaugeChart
                  animate={false}
                  hideText
                  style={{ height: 250 }}
                  id="gauge-chart1"
                  nrOfLevels={3}
                  colors={["#4CCEB7", "#FFB547", "#E91E63"]}
                  arcWidth={0.3}
                  percent={gaugeData ? gaugeData.positive / 300 : 0.1}
                  // arcsLength={[0.3, 0.5, 0.2]}
                  arcPadding={0}
                  cornerRadius={3}
                />
              )}
            </GraphCard>
            <GraphCard
              options={categories}
              name={categories[0]?.name}
              onChange={setSelectedOptionKeywords}
              title="Sentiment by Keyword"
              withFooter
            >
              {loadingStates.keyword ? (
                <SpiningPage opacity={1} />
              ) : (
                <SocialTable
                  keyName="keyword"
                  value="percentage"
                  items={keywordsData}
                />
              )}
            </GraphCard>
            <GraphCard
              options={categories}
              name={categories[0]?.name}
              onChange={setSelectedOptionLanguages}
              title="Language"
            >
              {loadingStates.language ? (
                <SpiningPage opacity={1} />
              ) : (
                <SocialTable
                  keyName="languages"
                  value="percentage"
                  items={languagesData}
                />
              )}
            </GraphCard>
            <GraphCard
              options={categories}
              name={categories[0]?.name}
              onChange={setSelectedOptionSources}
              title="Sources"
            >
              {loadingStates.source ? (
                <SpiningPage opacity={1} />
              ) : (
                <SocialTable
                  keyName="sources"
                  value="percentage"
                  items={socialData}
                />
              )}
            </GraphCard>
          </Row>
        )}
      </Layout>
    </Suspense>
  );
};

export default SocialAnalytics;

import React, { useContext, useState } from "react";
import "./CreateFlagComment.scss";
import { Row, Col, Mentions, Avatar } from "antd";
import { EditIcon } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import { PrimaryButton } from "components/atoms/controls";

// Missing create flag comment

const { Option } = Mentions;

const CreateFlagComment = ({
    onSubmit,
    onSelect,
    options = [],
    replyBoxRef,
}) => {
    const { translate } = useContext(I18nContext);
    const [isInputFocused, setInputFocused] = useState(false);
    const [comment, setComment] = useState("");

    const handleSubmit = (comment) => {
        onSubmit(comment);
        setComment("");
    };

    return (
        <Col className="comments-create-container">
            <Row className="header" align="middle">
                <EditIcon />
                <b style={{ marginLeft: "20px" }}>
                    {translate("pages").flags.flagDetails.addComments}
                </b>
            </Row>
            {/* TODO: Mention system */}
            <Mentions
                ref={replyBoxRef}
                className="comment-input"
                style={{ width: "100%" }}
                onChange={setComment}
                onSelect={onSelect}
                value={comment}
                autoSize={isInputFocused ? { minRows: 3, maxRows: 6 } : false}
                onBlur={() => setInputFocused(false)}
                onFocus={() => setInputFocused(true)}
                placeholder={`${
                    translate("pages").flags.flagDetails.comments
                }...`}
            >
                {options.map(({ value, name, image }) => (
                    <Option key={value} value={value}>
                        <Avatar size="small" src={image} /> {name}
                    </Option>
                ))}
            </Mentions>
            <Row justify="end">
                <PrimaryButton
                    cssClassName="send-btn"
                    onClick={() => handleSubmit(comment)}
                    title={translate("pages").flags.flagDetails.send}
                />
            </Row>
            {/* {isInputFocused && (
            )} */}
        </Col>
    );
};
export default CreateFlagComment;

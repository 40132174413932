import React from "react";
import "./StatCard.scss";
import { Card, Row, Col, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const StatCard = ({
    name,
    value = 0,
    icon,
    color = "#00B999",
    helper,
    background = "#e6f8f5",
}) => {
    const Icon = icon;
    return (
        <Card className="irys-stat-card">
            <Row justify="space-between" wrap={false}>
                <Col flex="auto">
                    <div className="value">{value}</div>
                    <div className="name">
                        {name}
                        {helper && (
                            <span style={{ marginLeft: 10, cursor: "pointer" }}>
                                <Popover content={<span>{helper}</span>}>
                                    <InfoCircleOutlined />
                                </Popover>
                            </span>
                        )}
                    </div>
                </Col>
                <Col
                    className="icon-container"
                    style={{ backgroundColor: background }}
                >
                    <Icon color={color} className="icon" />
                </Col>
            </Row>
        </Card>
    );
};

export default StatCard;

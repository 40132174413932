import React, { Suspense, useContext, useEffect, useState } from "react";
import "./Demo.scss";
import { FlagsIcon, SpiningPage, UserIcon } from "components/atoms/icons";
import BarGraph from "components/molecules/Graphs/Bar";
import { Row, Col, Spin } from "antd";
import { I18nContext } from "utils/i18n/locale";
import PieGraph, { TYPE } from "components/molecules/Graphs/Pie";
import {
  LAYOUT,
  LEGEND_DIRECTION,
  LEGEND_POSITION,
} from "components/molecules/Graphs";
import ScatterPlot from "components/molecules/Graphs/Scatter";
import LinePlot from "components/molecules/Graphs/Line";
import RadarGraph from "components/molecules/Graphs/Radar";
import StatCard from "components/molecules/Graphs/StatCard";
import Map from "components/atoms/map";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectResume,
  getProjectResumeLaunchSa,
  openCreateProjectModal,
} from "redux/slices/projects";
import { getFlags } from "redux/slices/flags";
import { getCurrentProjectId } from "utils/common";
import EmptyComponentTemplate from "components/templates/EmptyComponentTemplate";
import noProjects from "assets/img/no-projects.png";
import RankByZipcode from "components/organisms/Analytics/RankByZipcode";
import { AtMentionIcon } from "components/atoms/icons";
import { newFlagsIcon } from "components/atoms/icons";

const renderLoader = () => <SpiningPage opacity={1} />;

const Demo = () => {
  const { translate } = useContext(I18nContext);
  const currentProject = useSelector((state) => state.projects.currentProject);
  const resumeFetched = useSelector((state) => state.projects.resume);
  const analyticsFetched = useSelector(
    (state) => state.projects.launchsaAnalytics
  );
  const error = useSelector((state) => state.projects.resumeError);
  const projectsFetched = useSelector((state) => state.projects.projects);

  const flagsFetched = useSelector((state) => state.flags.flags);
  const loading = useSelector((state) => state.projects.loadingAction);
  const projectsLoading = useSelector((state) => state.projects.loading);

  const [flags, setFlags] = useState(flagsFetched);
  const [resume, setResume] = useState(resumeFetched);

  const [analytics, setAnalytics] = useState(analyticsFetched);

  const [permissions, setPermissions] = useState(currentProject?.permissions);

  const dispatch = useDispatch();

  useEffect(() => {
    setResume(resumeFetched);
  }, [resumeFetched]);
  useEffect(() => {
    console.log({ analyticsFetched });
    setAnalytics(analyticsFetched);
  }, [analyticsFetched]);

  useEffect(() => {
    setPermissions(currentProject?.permissions);
  }, [currentProject]);

  // useEffect(() => {
    // if (error && !getCurrentProjectId()) dispatch(getProjectResume());
  // }, [error]);

  useEffect(() => {
    dispatch(getProjectResume());
    dispatch(getProjectResumeLaunchSa());
  }, [projectsFetched]);

  // const BAR_GRAPH_DATA = [
  //   {
  //     department: "Management",
  //     [translate("pages").analytics.taskClosed]: 200,
  //     [translate("pages").analytics.flagsAssigned]: 100,
  //   },
  //   {
  //     department: "Security",
  //     [translate("pages").analytics.taskClosed]: 150,
  //     [translate("pages").analytics.flagsAssigned]: 100,
  //   },
  //   {
  //     department: "Finance",
  //     [translate("pages").analytics.taskClosed]: 100,
  //     [translate("pages").analytics.flagsAssigned]: 100,
  //   },
  //   {
  //     department: "Medical",
  //     [translate("pages").analytics.taskClosed]: 50,
  //     [translate("pages").analytics.flagsAssigned]: 100,
  //   },
  //   {
  //     department: "Education",
  //     [translate("pages").analytics.taskClosed]: 250,
  //     [translate("pages").analytics.flagsAssigned]: 100,
  //   },
  // ];
  const PIE_TASK_PRIORITY = [
    {
      id: "low",
      label: translate("pages").analytics.low,
      value: 233,
      color: "#00B999",
    },
    {
      id: "medium",
      label: translate("pages").analytics.medium,
      value: 466,
      color: "#FFB547",
    },
    {
      id: "high",
      label: translate("pages").analytics.high,
      value: 298,
      color: "#F44336",
    },
  ];

  // const BAR_BY_CATEGORY = [
  //   {
  //     category: "Environment",
  //     value: 2,
  //     color: "#00B999",
  //   },
  //   {
  //     category: "Building and Streets",
  //     value: 4,
  //     color: "#28E1C1",
  //   },
  //   {
  //     category: "Traffic and Transport",
  //     value: 8,
  //     color: "#6DFFE2",
  //   },
  //   {
  //     category: "Community Safety",
  //     value: 6.5,
  //     color: "#3CF5D5",
  //   },
  //   {
  //     category: "Leisure and Recreation",
  //     value: 2.5,
  //     color: "#14CDAD",
  //   },
  //   {
  //     category: "Community Facilities",
  //     value: 6,
  //     color: "#61E6BB",
  //   },
  //   {
  //     category: "Housing",
  //     value: 1,
  //     color: "#75FACF",
  //   },
  // ];
  const PIE_TIME_LATE = [
    {
      id: "on-time",
      label: "On Time",
      value: 75,
      color: "#00B999",
    },
    {
      id: "expired",
      label: "Expired",
      value: 25,
      color: "#C43A5A",
    },
  ];
  const BAR_TASK_COMPLETION = [
    {
      category: "Graffiti",
      [translate("pages").analytics.completedBefore]: 604,
      [translate("pages").analytics.completedAfter]: -155,
    },
    {
      category: "Parks",
      [translate("pages").analytics.completedBefore]: 32,
      [translate("pages").analytics.completedAfter]: -20,
    },
    {
      category: "Property Maintenace",
      [translate("pages").analytics.completedBefore]: 300,
      [translate("pages").analytics.completedAfter]: -101,
    },
    {
      category: "Streets/Traffic",
      [translate("pages").analytics.completedBefore]: 7,
      [translate("pages").analytics.completedAfter]: 0,
    },
    {
      category: "Scooters/Dockless Vehicles",
      [translate("pages").analytics.completedBefore]: 430,
      [translate("pages").analytics.completedAfter]: -168,
    },
    {
      category: "Sidewalks",
      [translate("pages").analytics.completedBefore]: 604,
      [translate("pages").analytics.completedAfter]: -155,
    },
    {
      category: "Water Services",
      [translate("pages").analytics.completedBefore]: 32,
      [translate("pages").analytics.completedAfter]: -20,
    },
    {
      category: "Public Works",
      [translate("pages").analytics.completedBefore]: 300,
      [translate("pages").analytics.completedAfter]: -101,
    },
    {
      category: "Animals",
      [translate("pages").analytics.completedBefore]: 7,
      [translate("pages").analytics.completedAfter]: 0,
    },
    {
      category: "Garbage/Dumping",
      [translate("pages").analytics.completedBefore]: 430,
      [translate("pages").analytics.completedAfter]: -168,
    },
  ];
  const SCATTER_DAYS_APPROVE = [
    {
      x: 0.15,
      y: 7.4,
      size: 2,
      category: "Animals",
    },
    {
      x: 0.1,
      y: 2.3,
      size: 7,
      category: "Property Maintenance",
    },
    {
      x: 0.38,
      y: 3.2,
      size: 5,
      category: "Water Services",
    },
    {
      x: 0.5,
      y: 7.65,
      size: 12,
      category: "Public Works",
    },
  ];
  const SCATTER_FLAG_APPROVAL = [
    {
      x: 0.15,
      y: 0.15,
      category: "Flag On Time",
      size: 1,
    },
    {
      x: 0.3,
      y: 0.53,
      category: "Expired Flags",
      size: 1,
    },
  ];
  const LINE_FLAG_STATUS = [
    {
      x: "2018-01-11",
      y: 300,
      category: "Pending",
    },
    {
      x: "2018-01-12",
      y: 300,
      category: "Pending",
    },
    {
      x: "2018-01-13",
      y: 300,
      category: "Pending",
    },
    {
      x: "2018-01-14",
      y: 300,
      category: "Pending",
    },
    {
      x: "2018-01-15",
      y: 300,
      category: "Pending",
    },
    {
      x: "2018-01-16",
      y: 300,
      category: "Pending",
    },
    {
      x: "2018-01-17",
      y: 300,
      category: "Pending",
    },
    {
      x: "2018-01-18",
      y: 300,
      category: "Pending",
    },
    {
      x: "2018-01-19",
      y: 300,
      category: "Pending",
    },
    {
      x: "2018-01-20",
      y: 300,
      category: "Pending",
    },
    {
      x: "2018-01-11",
      y: 100,
      category: "Open",
    },
    {
      x: "2018-01-12",
      y: 100,
      category: "Open",
    },
    {
      x: "2018-01-13",
      y: 100,
      category: "Open",
    },
    {
      x: "2018-01-14",
      y: 100,
      category: "Open",
    },
    {
      x: "2018-01-15",
      y: 100,
      category: "Open",
    },
    {
      x: "2018-01-16",
      y: 100,
      category: "Open",
    },
    {
      x: "2018-01-17",
      y: 100,
      category: "Open",
    },
    {
      x: "2018-01-18",
      y: 100,
      category: "Open",
    },
    {
      x: "2018-01-19",
      y: 100,
      category: "Open",
    },
    {
      x: "2018-01-20",
      y: 100,
      category: "Open",
    },
  ];
  const RADAR_TIME_TO_COMPLETE = [
    {
      category: "Less than a Week",
      "Streets/Traffic": 50,
      Parks: 40,
      Sidewalks: 0,
    },
    {
      category: "More than Four Weeks",
      "Streets/Traffic": 50,
      Parks: 30,
      Sidewalks: 12,
    },
    {
      category: "Two Weeks",
      "Streets/Traffic": 40,
      Parks: 5,
      Sidewalks: 30,
    },
    {
      category: "Four Weeks",
      "Streets/Traffic": 50,
      Parks: 60,
      Sidewalks: 40,
    },
    {
      category: "Three Weeks",
      "Streets/Traffic": 40,
      Parks: 70,
      Sidewalks: 5,
    },
  ];

  const BAR_BY_CATEGORY = [
    {
      category: "Growing",
      value: 30,
      color: "#29304D",
      data: [
        {
          name: "Real Estate",
          value: 12,
        },
        {
          name: "Funding",
          value: 10,
        },
        {
          name: "Hiring Employees",
          value: 5,
        },
        {
          name: "General Strategy",
          value: 3,
        },
        {
          name: "Marketing",
          value: 0,
        },
        {
          name: "Something else",
          value: 0,
        },
      ],
    },
    {
      category: "Getting Started",
      value: 20,
      color: "#003F4F",
      data: [
        {
          name: "Business Plan",
          value: 8,
        },
        {
          name: "Funding",
          value: 4,
        },
        {
          name: "Permits or Licenses",
          value: 3,
        },
        {
          name: "Legal Structures",
          value: 2,
        },
        {
          name: "Sales Channels",
          value: 2,
        },
        {
          name: "General Mentorship",
          value: 1,
        },
        {
          name: "Real Estate",
          value: 0,
        },
      ],
    },
    {
      category: "Shutting Down",
      value: 15,
      color: "#6DFFE2",
      data: [
        {
          name: "Legal",
          value: 10,
        },
        {
          name: "Something else",
          value: 5,
        },
      ],
    },
    {
      category: "Something More Complicated",
      value: 11,
      color: "#12D5C3",
    },
  ];

  const BAR_GRAPH_DATA = [
    {
      department: "Management",
      [translate("pages").analytics.taskClosed]: 200,
      // [translate("pages").analytics.flagsAssigned]: 100,
    },
    {
      department: "Security",
      [translate("pages").analytics.taskClosed]: 150,
      // [translate("pages").analytics.flagsAssigned]: 100,
    },
    {
      department: "Finance",
      [translate("pages").analytics.taskClosed]: 100,
      // [translate("pages").analytics.flagsAssigned]: 100,
    },
    {
      department: "Medical",
      [translate("pages").analytics.taskClosed]: 50,
      // [translate("pages").analytics.flagsAssigned]: 100,
    },
    {
      department: "Education",
      [translate("pages").analytics.taskClosed]: 250,
      // [translate("pages").analytics.flagsAssigned]: 100,
    },
  ];

  const PIE_BY_CATEGORY = [
    {
      id: "Real State",
      label: "Real State",
      value: 0.1,
      color: "#6DFFE2",
    },
    {
      id: "Marketing",
      label: "Marketing",
      value: 0.65,
      color: "#4CCEB8",
    },
    {
      id: "Bussiness",
      label: "Bussiness",
      value: 0.25,
      color: "#003F4F",
    },
  ];

  const data = {
    BAR_BY_CATEGORY: [
      {
        category: "Growing",
        value: 30,
        color: "#29304D",
        data: [
          {
            name: "Real Estate",
            value: 12,
          },
          {
            name: "Funding",
            value: 10,
          },
          {
            name: "Hiring Employees",
            value: 5,
          },
          {
            name: "General Strategy",
            value: 3,
          },
          {
            name: "Marketing",
            value: 0,
          },
          {
            name: "Something else",
            value: 0,
          },
        ],
      },
      {
        category: "Getting Started",
        value: 20,
        color: "#003F4F",
        data: [
          {
            name: "Business Plan",
            value: 8,
          },
          {
            name: "Funding",
            value: 4,
          },
          {
            name: "Permits or Licenses",
            value: 3,
          },
          {
            name: "Legal Structures",
            value: 2,
          },
          {
            name: "Sales Channels",
            value: 2,
          },
          {
            name: "General Mentorship",
            value: 1,
          },
          {
            name: "Real Estate",
            value: 0,
          },
        ],
      },
      {
        category: "Shutting Down",
        value: 15,
        color: "#6DFFE2",
        data: [
          {
            name: "Legal",
            value: 10,
          },
          {
            name: "Something else",
            value: 5,
          },
        ],
      },
      {
        category: "Something More Complicated",
        value: 11,
        color: "#12D5C3",
      },
    ],
    PIE_BY_CATEGORY: [
      {
        id: "Accounting/Finance",
        label: "Accounting/Finance",
        value: 0.1,
        color: "#6DFFE2",
      },
      {
        id: "Advertising/Public Relations",
        label: "Advertising/Public Relations",
        value: 0.65,
        color: "#4CCEB8",
      },
      {
        id: "Aerospace/Aviation",
        label: "Aerospace/Aviation",
        value: 0.25,
        color: "#003F4F",
      },
      {
        id: "Accounting/Finance",
        label: "Accounting/Finance",
        value: 0.1,
        color: "#6DFFE2",
      },
      {
        id: "Advertising/Public Relations",
        label: "Advertising/Public Relations",
        value: 0.65,
        color: "#4CCEB8",
      },
      {
        id: "Aerospace/Aviation",
        label: "Aerospace/Aviation",
        value: 0.25,
        color: "#003F4F",
      },
      {
        id: "Accounting/Finance",
        label: "Accounting/Finance",
        value: 0.1,
        color: "#6DFFE2",
      },
      {
        id: "Advertising/Public Relations",
        label: "Advertising/Public Relations",
        value: 0.65,
        color: "#4CCEB8",
      },
      {
        id: "Aerospace/Aviation",
        label: "Aerospace/Aviation",
        value: 0.25,
        color: "#003F4F",
      },
    ],
    CHART_BY_ZIP: [
      {
        rank: 1,
        zipCode: "03100",
        inputs: "300",
      },
      {
        rank: 2,
        zipCode: "03110",
        inputs: "240",
      },
      {
        rank: 3,
        zipCode: "03120",
        inputs: "105",
      },
      {
        rank: 4,
        zipCode: "03130",
        inputs: "80",
      },
    ],
  };


  return (
    <Suspense fallback={renderLoader()}>
      {(getCurrentProjectId() === "undefined" || isNaN(getCurrentProjectId()))&& (
        <EmptyComponentTemplate
          image={noProjects}
          long
          onButtonClick={() => dispatch(openCreateProjectModal())}
          title={translate("pages").signup.modals.noProject.title}
          subtitle={translate("pages").signup.modals.noProject.subtitle}
          buttonText={
            translate("pages").signup.modals.noProject.createProjectButton
          }
        />
      )}
      {projectsLoading && <SpiningPage opacity={1} />}

      {(getCurrentProjectId() !== "undefined" && !isNaN(getCurrentProjectId())) &&(
        <>
          <div className="demo">
            <h1 className="title"> Analytics</h1>
          </div>
          <Row gutter={[32, 32]}>
            {permissions?.projects?.read && (
              <>
                <Col flex="auto">
                  <StatCard
                    value={loading ? <Spin /> : resume?.flags?.pending}
                    icon={newFlagsIcon}
                    name="Pending flags"
                  />
                </Col>
                <Col flex="auto">
                  <StatCard
                    value={loading ? <Spin /> : resume?.flags?.open}
                    icon={newFlagsIcon}
                    name="Open flags"
                  />
                </Col>
                <Col flex="auto">
                  <StatCard
                    value={loading ? <Spin /> : resume?.flags?.rejected}
                    icon={newFlagsIcon}
                    // color="red"
                    name="Rejected flags"
                  />
                </Col>
                <Col flex="auto">
                  <StatCard
                    value={loading ? <Spin /> : resume?.flags?.resolved}
                    icon={newFlagsIcon}
                    name="Resolved flags"
                  />
                </Col>
              </>
            )}
            <Col flex="auto">
              <StatCard
                value={loading ? <Spin /> : resume?.users}
                icon={UserIcon}
                name="Users engaged"
                helper="Users registered on the mobile application"
              />
            </Col>
            <Col flex="auto">
              <StatCard
                value={loading ? <Spin /> : resume?.workers}
                icon={UserIcon}
                name="Team members"
                helper="Your teammates!"
              />
            </Col>
          </Row>
        </>
      )}
      {permissions && !permissions?.projects?.read && (
        <Row gutter={[32, 32]}>
          <Col flex="auto">
            <StatCard
              value={loading ? <Spin /> : analytics?.total_logins}
              icon={UserIcon}
              name="Total logins"
              helper="All users that have logged into the webapp"
            />
          </Col>
          <Col flex="auto">
            <StatCard
              value={loading ? <Spin /> : analytics?.total_conversation}
              icon={AtMentionIcon}
              name="Total conversations"
              helper="Total amount of conversations created"
            />
          </Col>
          <Col flex="auto">
            <StatCard
              value={loading ? <Spin /> : analytics?.new_chat}
              icon={AtMentionIcon}
              // color="red"
              name="New chats"
              helper="Amount of conversations created for the first time"
            />
          </Col>
        </Row>
      )}
      {permissions && !permissions?.projects?.read && (
        <Row gutter={[32, 32]}>
          {/* <Col md={12} xs={24}>
          <BarGraph
            title={"Users Engaged"}
            keyPoints={{
              [translate("pages").analytics.taskClosed]: "#75FACF",
              [translate("pages").analytics.flagsAssigned]: "#00B999",
            }}
            indexBy="department"
            data={BAR_GRAPH_DATA}
          />
        </Col> */}
          <Col md={24} lg={24}>
            {analytics?.PIE_BY_CATEGORY &&
              analytics?.PIE_BY_CATEGORY.length > 0 && (
                <PieGraph
                  type={TYPE.FULL}
                  legendDirection={LEGEND_DIRECTION.COLUMN}
                  legendPosition={LEGEND_POSITION.RIGHT}
                  title={"Users Industry"}
                  data={analytics?.PIE_BY_CATEGORY}
                />
              )}
          </Col>
          {/* <Col md={24} lg={12}>
          <RankByZipcode />
        </Col> */}
          <Col md={24} lg={24}>
            {analytics?.BAR_BY_CATEGORY &&
              analytics?.BAR_BY_CATEGORY.length > 0 && (
                <BarGraph
                  legendPosition={LEGEND_POSITION.RIGHT}
                  legendDirection={LEGEND_DIRECTION.COLUMN}
                  title={"By Needs"}
                  indexBy="category"
                  data={analytics?.BAR_BY_CATEGORY}
                />
              )}
          </Col>
          {/* <Col md={24} lg={12}>
          <BarGraph
            legendPosition={LEGEND_POSITION.RIGHT}
            legendDirection={LEGEND_DIRECTION.COLUMN}
            title={"Demographics"}
            indexBy="category"
            data={BAR_BY_CATEGORY}
          />
        </Col> */}
          {/* 
        <Col md={12} xs={24}>
          <BarGraph
            title={"Team Members"}
            keyPoints={{
              [translate("pages").analytics.taskClosed]: "#75FACF",
              [translate("pages").analytics.flagsAssigned]: "#00B999",
            }}
            indexBy="department"
            data={BAR_GRAPH_DATA}
          />
        </Col> */}
          {/* <Col md={24} lg={12}>
          <PieGraph
            type={TYPE.HALF}
            title={translate("pages").analytics.taskPriority}
            data={PIE_TASK_PRIORITY}
          />
        </Col>
        <Col xs={24}>
          <Map
            heatPoints={{
              positions: [
                { lat: 32.4556198, lng: 74.1847232 },
                { lat: 31.3558198, lng: 74.1847019 },
              ],
              options: {
                radius: 100,
                opacity: 1,
              },
            }}
            showPin={false}
            location={{ lat: 31.3558198, lng: 74.1847019 }}
            zoom={16}
            mapTypeControl={false}
          />
        </Col>
        <Col md={24} lg={12}>
          <PieGraph
            type={TYPE.FULL}
            legendDirection={LEGEND_DIRECTION.COLUMN}
            legendPosition={LEGEND_POSITION.BOTTOM}
            title={translate("pages").analytics.byCategory}
            data={PIE_BY_CATEGORY}
          />
        </Col>
        <Col md={24} lg={12}>
          <BarGraph
            legendPosition={LEGEND_POSITION.RIGHT}
            legendDirection={LEGEND_DIRECTION.COLUMN}
            title={translate("pages").analytics.byCategory}
            indexBy="category"
            data={BAR_BY_CATEGORY}
          />
        </Col>
        <Col md={24} lg={12}>
          <PieGraph
            type={TYPE.FULL}
            legendDirection={LEGEND_DIRECTION.ROW}
            legendPosition={LEGEND_POSITION.BOTTOM}
            reverse={true}
            title={translate("pages").analytics.byCategory}
            data={PIE_TIME_LATE}
          />
        </Col>
        <Col xs={24}>
          <BarGraph
            height={600}
            legendPosition={LEGEND_POSITION.BOTTOM}
            legendDirection={LEGEND_DIRECTION.ROW}
            layout={LAYOUT.HORIZONTAL}
            reverse={true}
            title={translate("pages").analytics.taskCompletedTitle}
            indexBy="category"
            data={BAR_TASK_COMPLETION}
            keyPoints={{
              [translate("pages").analytics.completedAfter]: "#C43A5A",
              [translate("pages").analytics.completedBefore]: "#00B999",
            }}
          />
        </Col>
        <Col xs={24}>
          <ScatterPlot
            height={600}
            legendPosition={LEGEND_POSITION.TOP}
            legendDirection={LEGEND_DIRECTION.ROW}
            layout={LAYOUT.HORIZONTAL}
            title={translate("pages").analytics.daysToApprove}
            subtitle={translate("pages").analytics.daysToApproveSub}
            xAxisLabel="Days to Approve"
            yAxisLabel="Days to Complete"
            data={SCATTER_DAYS_APPROVE}
            varyingSize={true}
            indexBy="category"
            maxXScale={0.7}
            maxYScale={10}
            keyPoints={{
              Animals: "#D1E76C",
              "Property Maintenance": "#75FACF",
              "Water Services": "#DA8BE3",
              "Public Works": "#A080E1",
            }}
          />
        </Col>
        <Col xs={24}>
          <ScatterPlot
            height={600}
            legendPosition={LEGEND_POSITION.TOP}
            legendDirection={LEGEND_DIRECTION.ROW}
            layout={LAYOUT.HORIZONTAL}
            title={translate("pages").analytics.flagApprovalAndCompletion}
            xAxisLabel="Days to Approve"
            yAxisLabel="Days to Complete"
            data={SCATTER_FLAG_APPROVAL}
            indexBy="category"
            maxXScale={1.0}
            maxYScale={1.0}
            varyingSize={false}
            keyPoints={{
              "Flag On Time": "#75FACF",
              "Expired Flags": "#C43A5A",
            }}
          />
        </Col>
        <Col xs={24}>
          <LinePlot
            height={600}
            legendPosition={LEGEND_POSITION.BOTTOM}
            legendDirection={LEGEND_DIRECTION.ROW}
            layout={LAYOUT.HORIZONTAL}
            title={translate("pages").analytics.flagStatus}
            data={LINE_FLAG_STATUS}
            indexBy="category"
            maxXScale={10}
            maxYScale={1000}
            animate={true}
            enableSlices="x"
            xScale={{
              type: "time",
              format: "%Y-%m-%d",
              useUTC: false,
              precision: "day",
            }}
            yScale={{
              max: 400,
            }}
            varyingSize={false}
            keyPoints={{
              Pending: "#75FACF",
              Open: "#DA8BE3",
            }}
          />
        </Col>
        <Col xs={24}>
          <RadarGraph
            height={600}
            legendPosition={LEGEND_POSITION.TOP}
            legendDirection={LEGEND_DIRECTION.ROW}
            layout={LAYOUT.HORIZONTAL}
            title={translate("pages").analytics.radarTimeToCompleteTask}
            data={RADAR_TIME_TO_COMPLETE}
            indexBy="category"
            animate={true}
            varyingSize={false}
            keyPoints={{
              "Streets/Traffic": "#0364FF",
              Parks: "#79C481",
              Sidewalks: "#5C5C5C",
            }}
          />
        </Col> */}
        </Row>
      )}
    </Suspense>
  );
};

export default Demo;

import api from "config/axios/axios.api.config";

const prefix = "events";

// const getEvents = () => api.get(`${prefix}`);

// const getEventsTopics = () => api.get(`${prefix}/topics`);

const getEvents = (id) => api.get(`${prefix}/projects/${id}`);
const getClientEvents = (id) => api.get(`${prefix}/projects/${id}`);
const getEventsTopics = (id) => api.get(`${prefix}/projects/${id}/topics`);

// const createEventTopic = (data) => api.postJson(`${prefix}/topics`, data);

const createEventTopic = (data, id) =>
    api.postJson(`${prefix}/projects/${id}/topics`, data);

const deleteEvent = (id) => api.put(`${prefix}/${id}/delete`, { id });

const publishEvent = (id) => api.put(`${prefix}/${id}/publish`, { id });

const unpublishEvent = (id) => api.put(`${prefix}/${id}/unpublish`, { id });

const duplicateEvent = (data) =>
    api.put(`${prefix}/${data.id}/duplicate`, data);

const updateEvent = (data, id) => api.put(`${prefix}/${id}`, data);

const createEvent = (data) => api.postJson(`${prefix}`, data);

// eslint-disable-next-line
export default {
    getEvents,
    deleteEvent,
    publishEvent,
    unpublishEvent,
    duplicateEvent,
    createEvent,
    updateEvent,
    getEventsTopics,
    createEventTopic,
    getClientEvents,
};

import React, { useContext } from "react";
import { Form, Row } from "antd";
import "./SocialAlertDrawer.scss";
import BottomDrawer from "components/atoms/bottom-drawer";
import { I18nContext } from "utils/i18n/locale";
import { DataInput } from "components/atoms/inputs";
import { PrimaryButton } from "components/atoms/controls";
import { useDispatch } from "react-redux";

const ActionFooter = ({ onSubmit, translate }) => {
  return (
    <Row justify="end" align="middle" gutter={2}>
      <PrimaryButton
        onClick={onSubmit}
        htmlType="submit"
        cssClassName="save-btn"
        title={translate("pages").settings.drawer.save}
      />
    </Row>
  );
};

const SocialAlertDrawer = ({ show, onClose }) => {
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = React.createRef();

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (data) => {};

  return (
    <BottomDrawer
      isSmall
      className="bottom-smaller"
      footer={
        <ActionFooter
          translate={translate}
          onSubmit={(e) => {
            formRef.current.submit();
            formRef.current
              .validateFields()
              .then(() => {
                onFinish();
              })
              .catch((err) => {
                console.error(err, "Missing fields");
              });
          }}
        />
      }
      onClose={onClose}
      open={show}
      title={"Create a new alert"}
    >
      <Form
        name="social-drawer"
        form={form}
        className="user-form"
        ref={formRef}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item key="alert-name" name="alert-name">
          <DataInput
            placeholder={"Add your alert"}
            title={"Alert Name"}
            allowClear
          />
        </Form.Item>
        <Form.Item key="alert-keywords" name="alert-keywords">
          <DataInput
            placeholder={"Add another keyword"}
            title={"Alert Keywords"}
            allowClear
          />
        </Form.Item>
      </Form>
    </BottomDrawer>
  );
};

export default SocialAlertDrawer;

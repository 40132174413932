import React, { Suspense, useEffect, useState } from "react";
import { SpiningPage } from "components/atoms/icons";
import { Col, Layout, Row, Select } from "antd";
import {
  FilterOutlined,
  HeartOutlined,
  MessageOutlined,
  PlusOutlined,
  RetweetOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { HappyFaceIcon } from "components/atoms/icons";
import { textTruncate } from "utils/common";
import { Tweet } from "react-twitter-widgets";
import { SadFaceIcon } from "components/atoms/icons";
import "./Inbox.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAlertCategories,
  getAlertKeywords,
  getAlerts,
} from "redux/slices/alerts";
import { NeutralFaceIcon } from "components/atoms/icons";
import { format } from "date-fns";

const { Option } = Select;

const renderLoader = () => <SpiningPage opacity={1} />;

const FilterSelect = ({
  placeholder,
  items,
  className,
  onChange,
  value,
  loading,
}) => (
  <Select
    size="small"
    className={className}
    showSearch
    placeholder={placeholder}
    optionFilterProp="children"
    onChange={(e) => onChange(e)}
    autoClearSearchValue
    value={value}
    loading={loading}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    {items?.map((item) => (
      <Option value={item.value}>{item.name}</Option>
    ))}
  </Select>
);

const BorderlessSelect = ({
  total,
  items,
  defaultValue,
  onChange,
  className,
}) => {
  return (
    <div className="borderless-select">
      <Select
        dropdownStyle={{ minWidth: "fit-content" }}
        className={className}
        defaultValue={defaultValue}
        bordered={false}
        onChange={onChange}
      >
        {items?.map((item) => (
          <Option value={item.id ?? item.value}>{item.name}</Option>
        ))}
      </Select>
      {total && <p className="select-subtitle"> {total} Mentions</p>}
    </div>
  );
};

const SocialCard = ({
  id,
  source,
  date,
  content,
  score,
  magnitude,
  onClick,
  current,
  likes,
  retweets,
}) => (
  <div
    className="social-card"
    onClick={
      current === id ? () => console.log("") : () => onClick({ id, score })
    }
    style={
      current === id
        ? {
            backgroundColor: "#F9FAFD",
            borderRight: `solid 10px${
              score === 0 ? "#FFBE5E" : score > 0 ? "#00B999" : "#E91E63"
            }`,
          }
        : { backgroundColor: "white" }
    }
  >
    <Row
      justify="start"
      align="middle"
      style={{
        padding: "15px",
        height: "100%",
        width: "10%",
      }}
    >
      <Col span={1}>
        <img
          alt="social"
          className="social-image"
          src={
            source === "twitter"
              ? "https://seeklogo.com/images/T/twitter-icon-circle-blue-logo-94339974C6-seeklogo.com.png"
              : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Facebook_f_logo_%282019%29.svg/1200px-Facebook_f_logo_%282019%29.svg.png"
          }
        />
      </Col>
    </Row>
    <Row justify="start" align="top" className="social-card-data">
      <Col span={24} className="social-card-content">
        <p>{textTruncate(content, 65, "...")}</p>
      </Col>
      <Col span={24}>
        <Row justify="start" align="top">
          {/* <Col span={3}>
            <p>
              <MessageOutlined /> {magnitude.toFixed(2)}
            </p>
          </Col> */}
          <Col span={3}>
            <p>
              <RetweetOutlined /> {retweets}
            </p>
          </Col>
          <Col span={3}>
            <p>
              <HeartOutlined /> {likes}
            </p>
          </Col>
          <Col span={4}>
            <p style={{ fontWeight: "bold" }}>
              {format(new Date(date), "MMM d yy")}
            </p>
          </Col>
          <Col
            span={11}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <p
              style={{
                color:
                  score === 0 ? "#FFBE5E" : score > 0 ? "#00B999" : "#E91E63",
              }}
            >
              {score === 0
                ? "Neutral Sentiment"
                : score > 0
                ? "Positive Sentiment"
                : "Negative Sentiment"}
            </p>
            <p>
              {score === 0 ? (
                <NeutralFaceIcon height={18} top={0} left={5} />
              ) : score > 0 ? (
                <HappyFaceIcon height={18} top={0} left={5} />
              ) : (
                <SadFaceIcon height={18} top={-4} left={5} />
              )}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);
const SENTIMENTS = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Positive",
    value: "pos",
  },
  {
    name: "Neutral",
    value: "neu",
  },
  {
    name: "Negative",
    value: "neg",
  },
];
const SOURCES = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Twitter",
    value: "twitter",
  },
  // {
  //   name: "Facebook",
  //   value: "facebook",
  // },
  // {
  //   name: "Google",
  //   value: "goo",
  // },
  // {
  //   name: "Redit",
  //   value: "red",
  // },
];

const SocialInbox = () => {
  const fetchedContent = useSelector((state) => state.alerts.content);
  const fetchedCategories = useSelector((state) => state.alerts.categories);
  const fetchedKeywords = useSelector((state) => state.alerts.keywords);
  const apiLoading = useSelector((state) => state.alerts.loading);
  const [alerts, setAlerts] = useState(fetchedCategories);
  const [alertSelected, setAlertSelected] = useState();
  const [keywords, setKeywords] = useState();
  const [sentimentSelected, setSentimentSelected] = useState();
  const [sourceSelected, setSourceSelected] = useState();
  const [keywordSelected, setKeywordSelected] = useState();
  const [content, setContent] = useState(fetchedContent);
  const [displayingTweet, setDisplayingTweet] = useState();
  const [filters, setFilters] = useState({ source: "twitter" });
  const [loading, setLoading] = useState(false);

  const filterAll = { name: "All", value: "all" };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAlertCategories());
  }, []);

  useEffect(() => {
    setAlerts([filterAll, ...fetchedCategories]);
  }, [fetchedCategories]);

  useEffect(() => {
    if (alertSelected) {
      if (alertSelected.id) dispatch(getAlertKeywords(alertSelected.id));
      if (alertSelected.value !== "all") {
        setFilters((currentFilters) => ({
          ...currentFilters,
          alert_id: alertSelected.id,
          keyword_id: null,
        }));
      } else {
        setFilters((currentFilters) => ({
          ...currentFilters,
          alert_id: null,
          keyword_id: null,
        }));
        setKeywords([]);
      }
      setKeywordSelected(null);
      // setKeywords(alertSelected?.keywords);
    }
  }, [alertSelected]);

  useEffect(() => {
    if (keywordSelected) {
      if (keywordSelected.value !== "all") {
        setFilters((currentFilters) => ({
          ...currentFilters,
          keyword_id: keywordSelected.id,
        }));
      } else {
        setFilters((currentFilters) => ({
          ...currentFilters,
          keyword_id: null,
        }));
      }
    }
  }, [keywordSelected]);

  useEffect(() => {
    setKeywords([filterAll, ...fetchedKeywords]);
  }, [fetchedKeywords]);

  useEffect(() => {
    setContent(fetchedContent);
    setDisplayingTweet(null);
  }, [fetchedContent]);

  useEffect(() => {
    dispatch(getAlerts(filters));
  }, [filters]);

  useEffect(() => {
    if (sentimentSelected) {
      if (sentimentSelected.value !== "all") {
        setFilters((currentFilters) => ({
          ...currentFilters,
          sentiment: sentimentSelected.value,
        }));
      } else {
        setFilters((currentFilters) => ({
          ...currentFilters,
          sentiment: null,
        }));
      }
    }
  }, [sentimentSelected]);

  useEffect(() => {
    if (sourceSelected) {
      if (sourceSelected.value !== "all") {
        setFilters((currentFilters) => ({
          ...currentFilters,
          source: sourceSelected.value,
        }));
      }
    }
  }, [sourceSelected]);

  const onClickCard = ({ id, score }) => {
    setLoading(true);
    setDisplayingTweet({ id, score });
  };

  const handleSelection = (arr, key, id, updateFunction) => {
    const updatedValue = arr.find((element) => element[key] === id);
    updateFunction(updatedValue);
  };

  return (
    <Suspense fallback={renderLoader()}>
      <Layout className="inbox">
        <Row justify="start" align="middle" className="filter" flex="auto">
          <Col>
            <FilterOutlined />
          </Col>
          <Col style={{ marginLeft: 10 }}>
            <p>FILTER BY</p>
          </Col>
          <Col offset="1">
            <FilterSelect
              value={alertSelected?.value ?? null}
              placeholder="ALERT"
              items={alerts}
              onChange={(e) =>
                handleSelection(alerts, "value", e, setAlertSelected)
              }
              className="select"
              loading={apiLoading}
            />
          </Col>
          <Col>
            <FilterSelect
              value={keywordSelected?.value ?? null}
              placeholder="KEYWORDS"
              items={keywords}
              onChange={(e) =>
                handleSelection(keywords, "value", e, setKeywordSelected)
              }
              className="select"
              loading={apiLoading}
            />
          </Col>
          <Col>
            <FilterSelect
              value={sentimentSelected?.value ?? null}
              placeholder="SENTIMENT"
              items={SENTIMENTS}
              className="select"
              onChange={(e) =>
                handleSelection(SENTIMENTS, "value", e, setSentimentSelected)
              }
              loading={apiLoading}
            />
          </Col>
          <Col>
            <FilterSelect
              value={sourceSelected?.value ?? null}
              placeholder="SOURCE"
              items={SOURCES}
              className="select"
              onChange={(e) =>
                handleSelection(SOURCES, "value", e, setSourceSelected)
              }
              loading={apiLoading}
            />
          </Col>
        </Row>

        <Row flex="auto" className="content">
          <Col className="social-panel">
            {/* <div className="alert-list">
              <p>Alert List</p>
              <Row justify="space-around" align="top" style={{ width: 50 }}>
                <Col style={{ cursor: "pointer" }} onClick={() => openDrawer()}>
                  <PlusOutlined />
                </Col>
                <Col style={{ cursor: "pointer" }} offset={1}>
                  <SearchOutlined />
                </Col>
              </Row>
            </div> */}
            <div className="alert-panel">
              <Row justify="start" align="bottom">
                {/* <Col>
                  <BorderlessSelect
                    defaultValue={alertSelected.name}
                    total={alertSelected?.total_mentions}
                    items={ALERTS}
                    onChange={(value) => setAlertSelected(ALERTS[value])}
                    className="selectbox-title"
                  />
                </Col>
                <Col>
                  <BorderlessSelect
                    items={alertSelected?.keywords}
                    defaultValue={"All"}
                  />
                </Col> */}

                <Col offset={1}>
                  <p className="alert-panel-title">Irys</p>
                </Col>
                <Col offset={1}>
                  <p className="alert-panel-subtitle">
                    {content?.length} Mentions
                  </p>
                </Col>
              </Row>
            </div>
            <div className="social-cards-container">
              {apiLoading && <SpiningPage opacity={1} />}
              {!apiLoading &&
                content?.map((item) => (
                  <SocialCard
                    {...item}
                    onClick={onClickCard}
                    current={displayingTweet?.id}
                  />
                ))}
            </div>
          </Col>

          <Col className="individual-content">
            <div className="sentiment-header">
              {displayingTweet &&
                (displayingTweet?.score === 0 ? (
                  <>
                    <div className="header-title">Neutral Sentiment</div>

                    <p>
                      <NeutralFaceIcon height={26} top={-2} left={5} />
                    </p>
                  </>
                ) : displayingTweet?.score > 0 ? (
                  <>
                    <div className="header-title">Positive Sentiment </div>
                    <p>
                      <HappyFaceIcon height={26} top={-2} left={5} />
                    </p>
                  </>
                ) : (
                  <>
                    <div className="header-title">Negative Sentiment </div>
                    <p>
                      <SadFaceIcon height={26} top={-2} left={5} />
                    </p>
                  </>
                ))}
              {}
            </div>
            <div className="content-card">
              {loading && <SpiningPage opacity={1} />}
              <Tweet
                tweetId={displayingTweet?.id}
                options={{ conversation: "" }}
                onLoad={() => setLoading(false)}
              />
            </div>
          </Col>
        </Row>
      </Layout>
    </Suspense>
  );
};

export default SocialInbox;

import React, { useContext, useEffect, useState } from "react";
import TabsMenu from "components/atoms/tabs";
import { withRouter } from "react-router-dom";
import InboxPage from "pages/SocialListening/Inbox";
import AnalyticsPage from "pages/SocialListening/Analytics";
import { I18nContext } from "utils/i18n/locale";
import "./SocialListeningTool.scss";
import { Col, Row, Divider, Spin } from "antd";
import { PrimaryButton } from "components/atoms/controls";
import { PlusOutlined } from "@ant-design/icons";
import StatCard from "components/molecules/Graphs/StatCard";
import EmptyComponentTemplate from "components/templates/EmptyComponentTemplate";
import noMentions from "assets/img/illustrations/no-mentions.svg";
import { HappyFaceIcon } from "components/atoms/icons";
import { SadFaceIcon } from "components/atoms/icons";
import { MentionsIcon } from "components/atoms/icons";
import SocialAlertDrawer from "./AddSocialAlertDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getAlertAnalytics } from "redux/slices/alerts";

const SocialListening = ({ history }) => {
  const { translate } = useContext(I18nContext);
  const [activeTab, setActiveTab] = useState("users");
  const fetchedAnalytics = useSelector((state) => state.alerts.analytics);
  const loading = useSelector((state) => state.alerts.analyticsLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAlertAnalytics());
  }, []);

  const [showDrawer, setShowDrawer] = useState(false);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <SocialAlertDrawer
        show={showDrawer}
        onClose={() => setShowDrawer((current) => !current)}
      />
      {false ? (
        <EmptyComponentTemplate
          image={noMentions}
          long
          onButtonClick={() => setShowDrawer((current) => !current)}
          title={"No mentions yet!"}
          subtitle={
            "Monitor your social media mentions,please create you first alert."
          }
          buttonText={"Create Alert"}
        />
      ) : (
        <>
          <Row justify="space-between" align="middle">
            <Col>
              <div className="settings-main-title">Mentions</div>
            </Col>
            <Col>
              <PrimaryButton
                onClick={() => {
                  setShowDrawer((current) => !current);
                }}
                icon={<PlusOutlined />}
                title="Create Alert"
              />
            </Col>
          </Row>
          <Divider />
          <Row gutter={[32, 32]}>
            <Col flex="auto">
              <StatCard
                background="#FEF6FF"
                color="#F0A1F9"
                value={loading ? <Spin /> : fetchedAnalytics?.total}
                icon={MentionsIcon}
                name="Total Mentions"
                // helper="Users registered on the mobile application"
              />
            </Col>
            <Col flex="auto">
              <StatCard
                background="#E6F8F5"
                color="#00B999"
                value={loading ? <Spin /> : fetchedAnalytics?.positive}
                icon={HappyFaceIcon}
                name="Positive Sentiment"
                // helper="Your teammates!"
              />
            </Col>
            <Col flex="auto">
              <StatCard
                background="#FDE9F0"
                color="#EB3874"
                value={loading ? <Spin /> : fetchedAnalytics?.negative}
                icon={SadFaceIcon}
                name="Negative Sentiment"
                // helper="Your teammates!"
              />
            </Col>
          </Row>
          <Divider />
          <TabsMenu
            baseRoute="home/mentions"
            onChange={onTabChange}
            // onChange={(item) => history.push(`home/settings/${item}`)}
            activeKey={activeTab}
            options={[
              {
                title: "Inbox",
                key: "inbox",
                children: (
                  <InboxPage
                    showDrawer={showDrawer}
                    openDrawer={() => setShowDrawer((current) => !current)}
                  />
                ),
              },
              {
                title: "Analytics",
                key: "analytics",
                children: <AnalyticsPage />,
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default withRouter(SocialListening);

import React from "react";
import "./CommentItem.scss";
import { Text } from "components/atoms/texts";
import { Avatar, Row, Col } from "antd";
import { cloudFrontUrl } from "utils/common";
import moment from "moment";
import { RetweetOutlined } from "@ant-design/icons";

const CommentItem = ({
    content,
    created_at,
    id,
    replies,
    reply_from_id,
    total_likes,
    user,
    className,
    setId,
}) => {
    console.log({ insideComents: setId });
    return (
        <Row className={`comment-item-root ${className}`}>
            <Avatar
                className="profile-image"
                size="large"
                src={cloudFrontUrl(user?.image)}
            >
                {user?.name?.charAt(0)}
            </Avatar>
            <Col flex="auto" className="comment-item-container">
                <Row justify="space-between">
                    <Text className="name" text={`${user?.name}:`} />
                    <Text
                        className="date"
                        text={`${moment(created_at).fromNow()}`}
                    />
                </Row>
                <Text className="subtitle" text={content} />
                {replies &&
                    replies.length > 0 &&
                    replies.map((reply) => (
                        <>
                            <div className="comment-reply-container">
                                <Avatar
                                    className="reply-avatar"
                                    size="small"
                                    src={cloudFrontUrl(reply?.user?.image)}
                                >
                                    {reply?.user?.name?.charAt(0)}
                                </Avatar>
                                <Text
                                    className="name"
                                    text={`${reply?.user?.name}:`}
                                />
                            </div>
                            <Text
                                className="subtitle reply-sub"
                                text={reply?.content}
                            />
                            <Text
                                className="date reply-sub"
                                text={`${moment(reply?.created_at).fromNow()}`}
                            />
                        </>
                    ))}
                <Col onClick={() => setId(id)} className="reply-cursor">
                    <Text className="date " text={<RetweetOutlined />} />
                    <Text className="date" text={`reply`} />
                </Col>
            </Col>
        </Row>
    );
};
export default CommentItem;

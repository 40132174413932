import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import alertApi from "api/alertAPI";
import { getCurrentProjectId } from "utils/common";

const awsErrorSerialized = {
  error: "ASW Error",
};

const awsError = (resp) => {
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};

export const getAlerts = createAsyncThunk(
  "alerts/getAlerts",
  async (data, { rejectWithValue }) => {
    try {
      const id = getCurrentProjectId();
      const alerts = await alertApi.getAlertData(id, data);
      console.log({ alerts });
      awsError(alerts);
      if (alerts.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return alerts.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getAlertCategories = createAsyncThunk(
  "alerts/getAlertCategories",
  async (rejectWithValue) => {
    try {
      const id = getCurrentProjectId();
      const categories = await alertApi.getAlertCategories(id);
      awsError(categories);
      if (categories.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return categories.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getAlertKeywords = createAsyncThunk(
  "alerts/getAlertKeywords",
  async (id, { rejectWithValue }) => {
    try {
      const keywords = await alertApi.getAlertKeywords(id);
      awsError(keywords);
      if (keywords.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return keywords.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const getAlertAnalytics = createAsyncThunk(
  "alerts/getAlertAnalytics",
  async (alert_id, { rejectWithValue }) => {
    try {
      const id = getCurrentProjectId();
      const analytics = await alertApi.getAlertAnalytics(id, alert_id);
      awsError(analytics);
      if (analytics.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return analytics.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

const initialState = {
  content: [],
  categories: [],
  keywords: [],
  analytics: null,
  error: null,
  loading: true,
  analyticsLoading: true,
};

const alerts = createSlice({
  name: "alerts",
  initialState,
  reducers: {},
  extraReducers: {
    //Get Alerts
    [getAlerts.pending]: (state, action) => {
      console.info("getAlerts pending");
      state.loading = true;
      state.error = null;
    },
    [getAlerts.fulfilled]: (state, action) => {
      console.info("getAlerts fullfilled");
      state.loading = false;
      state.error = null;
      state.content = action.payload.body;
    },
    [getAlerts.rejected]: (state, action) => {
      console.error("getAlerts rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.content = [];
    },
    //Get categories
    [getAlertCategories.pending]: (state, action) => {
      console.info("getAlertCategories pending");
      state.loading = true;
      state.error = null;
    },
    [getAlertCategories.fulfilled]: (state, action) => {
      console.info("getAlertCategories fullfilled");
      state.loading = false;
      state.error = null;
      state.categories = action.payload.body;
    },
    [getAlertCategories.rejected]: (state, action) => {
      console.error("getAlertCategories rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.categories = [];
    },
    //Get keywords
    [getAlertKeywords.pending]: (state, action) => {
      console.info("getAlertKeywords pending");
      state.loading = true;
      state.error = null;
    },
    [getAlertKeywords.fulfilled]: (state, action) => {
      console.info("getAlertKeywords fullfilled");
      state.loading = false;
      state.error = null;
      state.keywords = action.payload.body;
    },
    [getAlertKeywords.rejected]: (state, action) => {
      console.error("getAlertKeywords rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.keywords = [];
    },
    //Get Analytics
    [getAlertAnalytics.pending]: (state, action) => {
      console.info("getAlertAnalytics pending");
      state.loading = true;
      state.analyticsLoading = true;
      state.error = null;
    },
    [getAlertAnalytics.fulfilled]: (state, action) => {
      console.info("getAlertAnalytics fullfilled");
      state.loading = false;
      state.analyticsLoading = false;
      state.error = null;
      state.analytics = action.payload.body;
    },
    [getAlertAnalytics.rejected]: (state, action) => {
      console.error("getAlertAnalytics rejected =>", action.payload);
      state.loading = false;
      state.analyticsLoading = false;
      state.error = action.payload;
      state.analytics = [];
    },
  },
});

// export const {

// } = news.actions;
export default alerts.reducer;

import React, {useContext} from 'react'
import {Col, Row} from "antd";
import StatCard from "components/molecules/Graphs/StatCard";
import {I18nContext} from "utils/i18n/locale";
import './FlagsStatus.scss';
import { newFlagsIcon } from 'components/atoms/icons';

const FlagsStatus = () => {
	const { translate } = useContext(I18nContext);

	return (
		<Row className='flags-status-graph-root' gutter={[2, 0]}>
			<Col md={24/3} sm={24} flex="auto">
				<StatCard value={329} icon={newFlagsIcon} name={translate("pages").analytic.editor.charts.flagsStatus.openFlags}/>
			</Col>
			<Col md={24/3} sm={24} flex="auto">
				<StatCard value={42} icon={newFlagsIcon} name={translate("pages").analytic.editor.charts.flagsStatus.pendingFlags}/>
			</Col>
			<Col md={24/3} sm={24} flex="auto">
				<StatCard value={13} icon={newFlagsIcon} name={translate("pages").analytic.editor.charts.flagsStatus.closeFlags}/>
			</Col>
		</Row>
	)
}

export default FlagsStatus;

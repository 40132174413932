/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:354e6cb5-edc7-4495-a0fa-f296968d204f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3sTAxuL4M",
    "aws_user_pools_web_client_id": "3e362hpnfkh7e23kkm431rrlhp",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "irysplatformdev134207-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

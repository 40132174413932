import api from "config/axios/axios.api.config";

const prefix = "alerts";

const getAlertData = (id, data) => api.postJson(`${prefix}/${id}`, data);
const getAlertCategories = (id) => api.get(`${prefix}/${id}/categories`);
const getAlertKeywords = (id) => api.get(`${prefix}/${id}/keywords`);
const getAlertAnalytics = (id, alert_id) =>
  api.get(`${prefix}/analytics/${id}${alert_id ? "/" + alert_id : ""}`);

// eslint-disable-next-line
export default {
  getAlertData,
  getAlertCategories,
  getAlertKeywords,
  getAlertAnalytics,
};
